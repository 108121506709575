var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.showImportModal ? _c('employees-import-modal', {
    attrs: {
      "show-import-modal": _vm.showImportModal
    },
    on: {
      "update:showImportModal": function updateShowImportModal($event) {
        _vm.showImportModal = $event;
      },
      "update:show-import-modal": function updateShowImportModal($event) {
        _vm.showImportModal = $event;
      },
      "fetch-data": _vm.refetchData
    }
  }) : _vm._e(), _c('EmloyeesListFilter', {
    attrs: {
      "type-filter": _vm.typeFilter,
      "created-by-filter": _vm.createdByFilter,
      "gender-filter": _vm.genderFilter,
      "status-filter": _vm.statusFilter,
      "employee-code": _vm.employeeCode,
      "type-of-employee-options-filter": _vm.typeOfEmployeeOptionsFilter,
      "gender-options": _vm.genderOptions,
      "status-options": _vm.statusOptions,
      "empty-filter": _vm.isEmptyFilter
    },
    on: {
      "update:typeFilter": function updateTypeFilter($event) {
        _vm.typeFilter = $event;
      },
      "update:type-filter": function updateTypeFilter($event) {
        _vm.typeFilter = $event;
      },
      "update:createdByFilter": function updateCreatedByFilter($event) {
        _vm.createdByFilter = $event;
      },
      "update:created-by-filter": function updateCreatedByFilter($event) {
        _vm.createdByFilter = $event;
      },
      "update:genderFilter": function updateGenderFilter($event) {
        _vm.genderFilter = $event;
      },
      "update:gender-filter": function updateGenderFilter($event) {
        _vm.genderFilter = $event;
      },
      "update:statusFilter": function updateStatusFilter($event) {
        _vm.statusFilter = $event;
      },
      "update:status-filter": function updateStatusFilter($event) {
        _vm.statusFilter = $event;
      },
      "update:employeeCode": function updateEmployeeCode($event) {
        _vm.employeeCode = $event;
      },
      "update:employee-code": function updateEmployeeCode($event) {
        _vm.employeeCode = $event;
      },
      "fetch-data": _vm.refetchData,
      "reset": _vm.clearFilter
    }
  }), _c('b-card', {
    staticClass: "mb-0",
    staticStyle: {
      "max-height": "70vh"
    },
    attrs: {
      "no-body": ""
    }
  }, [_c('div', {
    staticClass: "mx-2 my-1"
  }, [_c('b-row', {
    staticClass: "justify-content-md-between flex-md-nowrap",
    attrs: {
      "align-v": "center"
    }
  }, [_c('b-col', {
    staticClass: "px-0 mr-md-1",
    attrs: {
      "cols": "4",
      "md": "auto"
    }
  }, [_c('v-select', {
    staticClass: "per-page-selector d-inline-block mx-50",
    attrs: {
      "options": _vm.sizePerPageLgOptions,
      "clearable": false
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function fn(data) {
        return [_c('span', [_vm._v(" " + _vm._s(data.label) + " ")])];
      }
    }, {
      key: "selected-option",
      fn: function fn(data) {
        return [_c('span', [_vm._v(" " + _vm._s(data.label) + " ")])];
      }
    }, {
      key: "no-options",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
      },
      proxy: true
    }]),
    model: {
      value: _vm.sizePerPage,
      callback: function callback($$v) {
        _vm.sizePerPage = $$v;
      },
      expression: "sizePerPage"
    }
  }, [_c('span', {
    staticClass: "text-muted ml-50"
  }, [_vm._v(_vm._s(_vm.$t('employee.employees')))])])], 1), _c('b-col', {
    staticClass: "d-flex justify-content-end px-50",
    attrs: {
      "cols": "8",
      "md": "auto",
      "order-md": "3"
    }
  }, [_c('div', {
    staticClass: "d-none d-sm-flex"
  }, [_c('b-button', {
    staticClass: "mr-1 px-lg-1 px-sm-75",
    attrs: {
      "disabled": !_vm.canAccess('employee.createEmployee'),
      "variant": "info"
    },
    on: {
      "click": function click() {
        return _vm.$router.push({
          name: 'apps-employees-add'
        });
      }
    }
  }, [_c('span', {
    staticClass: "text-nowrap"
  }, [_c('feather-icon', {
    staticClass: "cursor-pointer",
    attrs: {
      "icon": "PlusSquareIcon",
      "size": "16"
    }
  }), _c('span', {
    staticClass: "d-none d-sm-inline ml-50"
  }, [_vm._v(_vm._s(_vm.$t('employee.btnCreate')))])], 1)]), _c('b-button', {
    staticClass: "mr-1 px-lg-1 px-sm-75",
    attrs: {
      "variant": "info",
      "disabled": !_vm.canAccess('employee.importEmployee')
    },
    on: {
      "click": _vm.showImportModalHandle
    }
  }, [_c('span', {
    staticClass: "text-nowrap"
  }, [_c('feather-icon', {
    staticClass: "cursor-pointer",
    attrs: {
      "icon": "UploadIcon",
      "size": "16"
    }
  }), _c('span', {
    staticClass: "d-none d-sm-inline ml-50"
  }, [_vm._v(_vm._s(_vm.$t('import')))])], 1)]), _c('b-button', {
    staticClass: "px-lg-1 px-sm-75",
    attrs: {
      "variant": "info",
      "disabled": !_vm.canAccess('employee.exportEmployee')
    },
    on: {
      "click": function click($event) {
        return _vm.confirmExport();
      }
    }
  }, [_c('span', {
    staticClass: "text-nowrap"
  }, [_c('feather-icon', {
    staticClass: "cursor-pointer",
    attrs: {
      "icon": "DownloadIcon",
      "size": "16"
    }
  }), _c('span', {
    staticClass: "d-none d-sm-inline ml-50"
  }, [_vm._v(_vm._s(_vm.$t('export')))])], 1)])], 1), _c('div', {
    staticClass: "d-block d-sm-none"
  }, [_c('b-dropdown', {
    staticClass: "m-md-2",
    attrs: {
      "variant": "info",
      "boundary": "window",
      "right": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function fn() {
        return [_c('feather-icon', {
          attrs: {
            "icon": "MenuIcon",
            "size": "16"
          }
        }), _c('span', {
          staticClass: "pl-50"
        }, [_vm._v(_vm._s(_vm.$t('employee.moreDropdown.title')))])];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', {
    attrs: {
      "disabled": !_vm.canAccess('employee.createEmployee')
    },
    on: {
      "click": function click() {
        return _vm.$router.push({
          name: 'apps-employees-add'
        });
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('employee.moreDropdown.add')) + " ")]), _c('b-dropdown-item', {
    attrs: {
      "disabled": !_vm.canAccess('employee.exportEmployee')
    },
    on: {
      "click": function click($event) {
        return _vm.confirmExport();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('employee.moreDropdown.export')) + " ")])], 1)], 1)]), _c('b-col', {
    staticClass: "mt-1 mt-md-0 px-50 flex-grow-1",
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('b-input-group', {
    attrs: {
      "size": "md"
    },
    scopedSlots: _vm._u([{
      key: "prepend",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex align-items-center border rounded-left px-1 bg-light-info"
        }, [_c('feather-icon', {
          attrs: {
            "icon": "SearchIcon",
            "size": "20"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-form-input', {
    attrs: {
      "disabled": !_vm.canAccess('employee.searchEmployee'),
      "type": "search",
      "placeholder": _vm.$t('employee.placeholderSearch'),
      "value": _vm.searchTextFilter,
      "reduce": function reduce(val) {
        return val.value;
      },
      "maxlength": "255",
      "debounce": "500",
      "trim": ""
    },
    on: {
      "input": function input(val) {
        return _vm.$emit('update:searchTextFilter', val);
      }
    },
    model: {
      value: _vm.searchTextFilter,
      callback: function callback($$v) {
        _vm.searchTextFilter = $$v;
      },
      expression: "searchTextFilter"
    }
  })], 1)], 1)], 1)], 1), _c('b-overlay', {
    attrs: {
      "show": _vm.loading,
      "rounded": "sm",
      "no-fade": "",
      "variant": "transparent",
      "opacity": 0.5
    }
  }, [_c('b-table', {
    ref: "refEmployeeListTable",
    staticStyle: {
      "max-height": "46vh"
    },
    attrs: {
      "sticky-header": "",
      "items": _vm.fetchEmployeesByFilter,
      "responsive": "",
      "fields": _vm.tableColumns,
      "primary-key": "id",
      "show-empty": "",
      "empty-text": _vm.$t('noMatchingResult'),
      "sort-desc": _vm.isSortDirDesc,
      "sort-by": _vm.sortBy,
      "no-border-collapse": ""
    },
    on: {
      "update:sortDesc": function updateSortDesc($event) {
        _vm.isSortDirDesc = $event;
      },
      "update:sort-desc": function updateSortDesc($event) {
        _vm.isSortDirDesc = $event;
      },
      "update:sortBy": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sort-by": function updateSortBy($event) {
        _vm.sortBy = $event;
      }
    },
    scopedSlots: _vm._u([_vm._l(_vm.tableColumns, function (column) {
      return {
        key: "head(".concat(column.key, ")"),
        fn: function fn(data) {
          return [_c('span', {
            key: column.label,
            staticClass: "text-dark text-nowrap"
          }, [_vm._v(" " + _vm._s(_vm.$t("employee.".concat(data.label))) + " ")])];
        }
      };
    }), {
      key: "cell(employeeCode)",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "text-nowrap"
        }, [_c('b-link', {
          staticClass: "font-weight-bold d-block text-nowrap text-info",
          attrs: {
            "disabled": !_vm.canAccess('employee.detailEmployee'),
            "to": {
              name: 'apps-employees-edit',
              params: {
                id: data.item.id
              }
            }
          }
        }, [_c('span', {
          staticClass: "align-text-top text-capitalize font-weight-bold"
        }, [_vm._v(" " + _vm._s(data.item.employeeCode) + " ")])])], 1)];
      }
    }, {
      key: "cell(account)",
      fn: function fn(data) {
        return [_c('h5', {
          staticClass: "font-weight-bolder align-text-top"
        }, [_c('feather-icon', {
          attrs: {
            "size": "14",
            "icon": "UserIcon"
          }
        }), _vm._v(" " + _vm._s(data.item.username.toUpperCase() || 'N/A') + " ")], 1), _c('div', [_c('small', {
          staticClass: "align-items-center d-flex flex-nowrap"
        }, [_c('feather-icon', {
          staticClass: "mr-25",
          attrs: {
            "size": "14",
            "icon": "MailIcon"
          }
        }), _c('span', [_vm._v(_vm._s(data.item.emailAddress || 'N/A'))])], 1)]), _c('div', [_c('small', {
          staticClass: "align-items-center d-flex flex-nowrap"
        }, [_c('feather-icon', {
          staticClass: "mr-25",
          attrs: {
            "size": "14",
            "icon": "PhoneCallIcon"
          }
        }), _vm._v(" " + _vm._s(data.item.phoneNumber || 'N/A') + " ")], 1)])];
      }
    }, {
      key: "cell(information)",
      fn: function fn(data) {
        return [_c('b-media', {
          attrs: {
            "vertical-align": "center"
          },
          scopedSlots: _vm._u([{
            key: "aside",
            fn: function fn() {
              return [_c('b-avatar', {
                attrs: {
                  "size": "70",
                  "src": data.item.avatar,
                  "text": _vm.avatarText("".concat(data.item.lastName, " ").concat(data.item.firstName)),
                  "variant": "light-".concat(_vm.resolveEmployeeTypeVariant(data.item.type))
                }
              })];
            },
            proxy: true
          }], null, true)
        }, [_c('div', {
          staticClass: "d-flex flex-column justify-content-center align-items-start"
        }, [_c('h5', {
          staticClass: "mb-50 text-nowrap"
        }, [_vm._v(" " + _vm._s(data.item.lastName) + " " + _vm._s(data.item.firstName) + " ")]), _c('small', {
          staticClass: "d-flex justify-content-start align-items-center"
        }, [_c('feather-icon', {
          staticClass: "mr-25",
          attrs: {
            "size": "14",
            "icon": "AwardIcon"
          }
        }), _c('span', {
          class: "text-".concat(_vm.resolveEmployeeTypeVariant(data.item.type), " font-weight-bold")
        }, [_vm._v(" " + _vm._s(_vm.$te(_vm.resolveEmployeeTypeTitle(data.item.type)) ? _vm.$t(_vm.resolveEmployeeTypeTitle(data.item.type)) : _vm.resolveEmployeeTypeTitle(data.item.type)) + " ")])], 1), _c('small', {
          staticClass: "d-flex justify-content-start align-items-center"
        }, [_c('feather-icon', {
          staticClass: "mr-25",
          attrs: {
            "size": "14",
            "icon": "TagIcon"
          }
        }), _c('small', {}, [_vm._v(_vm._s(_vm.$t(_vm.resolveGender(data.item.gender, 'value', 'label'))) + " ")])], 1)])])];
      }
    }, {
      key: "cell(isActive)",
      fn: function fn(data) {
        return [_c('IAmSwitch', {
          attrs: {
            "checked": data.item.isActive,
            "name": "check-button",
            "confirm": true,
            "custom-class": "mr-0 mt-50 custom-control-success",
            "disabled": (data.item.isActive ? !_vm.canAccess('employee.activeEmployee') : !_vm.canAccess('employee.deactiveEmployee')) || data.item.id === _vm.meDataEmpId || _vm.getMeDataType !== 'ADM' && data.item.type === 'ADM'
          },
          on: {
            "update:checked": function updateChecked($event) {
              return _vm.$set(data.item, "isActive", $event);
            },
            "input": function input($event) {
              return _vm.changeActive(data.item);
            }
          }
        })];
      }
    }, {
      key: "cell(createdAt)",
      fn: function fn(data) {
        return [_c('b-media', {
          attrs: {
            "no-body": ""
          }
        }, [_c('b-media-aside', {
          staticClass: "mr-1"
        }, [_c('b-avatar', {
          attrs: {
            "rounded": "",
            "variant": "light-info",
            "size": "34"
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "ClockIcon",
            "size": "18"
          }
        })], 1)], 1), _c('b-media-body', [_c('h6', {
          staticClass: "mb-0 text-nowrap"
        }, [_vm._v(" " + _vm._s(_vm.dateTime(data.item.createdAt)) + " ")]), _c('small', {
          staticClass: "text-nowrap"
        }, [_vm._v(" " + _vm._s(data.item.createdBy ? "".concat(data.item.createdBy.firstName, " ").concat(data.item.createdBy.lastName) : ''))])])], 1)];
      }
    }, {
      key: "cell(updatedAt)",
      fn: function fn(data) {
        return [_c('b-media', {
          attrs: {
            "no-body": ""
          }
        }, [_c('b-media-aside', {
          staticClass: "mr-1"
        }, [_c('b-avatar', {
          attrs: {
            "rounded": "",
            "variant": "light-info",
            "size": "34"
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "EditIcon",
            "size": "18"
          }
        })], 1)], 1), _c('b-media-body', [_c('h6', {
          staticClass: "mb-0 text-nowrap"
        }, [_vm._v(" " + _vm._s(_vm.dateTime(data.item.updatedAt)) + " ")]), _c('small', {
          staticClass: "text-nowrap"
        }, [_vm._v(" " + _vm._s(data.item.updatedBy ? "".concat(data.item.updatedBy.firstName, " ").concat(data.item.updatedBy.lastName) : ''))])])], 1)];
      }
    }, {
      key: "cell(actions)",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('div', {
          staticClass: "d-flex-center"
        }, [_vm.isShowResetPasswordBtn(item) ? _c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(113, 102, 240, 0.15)',
            expression: "'rgba(113, 102, 240, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "btn-icon p-50 text-nowrap",
          attrs: {
            "variant": "info"
          },
          on: {
            "click": function click($event) {
              return _vm.handleShowModalChangePassword(item);
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("agency.employees.changePassword")) + " ")]) : _vm._e()], 1)];
      }
    }], null, true)
  })], 1), _c('div', {
    staticClass: "mx-2 mb-2"
  }, [_c('b-row', [_c('b-col', {
    staticClass: "d-flex align-items-center justify-content-center justify-content-sm-start",
    attrs: {
      "cols": "12",
      "sm": "2"
    }
  }, [_c('span', {
    staticClass: "text-muted text-nowrap"
  }, [_vm._v(_vm._s(_vm.dataMeta.from) + " " + _vm._s(_vm.$t('paginationText.to')) + " " + _vm._s(_vm.dataMeta.to) + " "), _c('span', {
    staticClass: "d-inline-block d-sm-none d-md-inline"
  }, [_vm._v(" " + _vm._s(_vm.$t('paginationText.outOf')) + " ")]), _c('span', {
    staticClass: "d-none d-sm-inline d-md-none"
  }, [_vm._v(" / ")]), _vm._v(" " + _vm._s(_vm.dataMeta.of) + " " + _vm._s(_vm.$t('paginationText.items')))])]), _c('b-col', {
    staticClass: "d-flex align-items-center justify-content-center",
    attrs: {
      "cols": "12",
      "sm": "8"
    }
  }, [_c('b-pagination', {
    staticClass: "mb-0 mt-1 mt-sm-0",
    attrs: {
      "total-rows": _vm.totalEmployees,
      "per-page": _vm.sizePerPage,
      "first-number": "",
      "last-number": "",
      "prev-class": "prev-item",
      "next-class": "next-item"
    },
    scopedSlots: _vm._u([{
      key: "prev-text",
      fn: function fn() {
        return [_c('feather-icon', {
          attrs: {
            "icon": "ChevronLeftIcon",
            "size": "18"
          }
        })];
      },
      proxy: true
    }, {
      key: "next-text",
      fn: function fn() {
        return [_c('feather-icon', {
          attrs: {
            "icon": "ChevronRightIcon",
            "size": "18"
          }
        })];
      },
      proxy: true
    }]),
    model: {
      value: _vm.currentPage,
      callback: function callback($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1)], 1)], 1)], 1), _c('ModalChangePasswordEmployee', {
    attrs: {
      "employees": _vm.employeeToResetPassword
    },
    on: {
      "update:employees": function updateEmployees($event) {
        _vm.employeeToResetPassword = $event;
      },
      "refetchAgency": _vm.refetchData
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }